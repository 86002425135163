//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const levels = {
  info: {
    title: 'messages.level_info',
    icon: 'outline/annotation',
    class: 'text-primary-300 _tid_message-info'
  },
  notice: {
    title: 'messages.level_notice',
    icon: 'outline/information-circle',
    class: 'text-primary-300 _tid_message-notice'
  },
  warning: {
    title: 'messages.level_warning',
    icon: 'solid/exclamation',
    class: 'text-yellow-600 _tid_message-warning'
  },
  error: {
    title: 'messages.level_error',
    icon: 'solid/exclamation-circle',
    class: 'text-red-600 _tid_message-error'
  }
}

export default {
  name: 'MessagesRow',
  props: {
    message: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    confirmCallback: {
      type: Function,
      default: undefined
    }
  },
  data() {
    return {
      showMore: false,
      isLoading: false
    }
  },
  computed: {
    showConfirmButton() {
      return (
        !!this.confirmCallback &&
        this.index === 0 &&
        this.message.confirmable &&
        !this.message.ts_conf &&
        !this.message.ts_rem
      )
    },
    code() {
      return this.message.code
    }
  },
  methods: {
    async confirm() {
      this.showMore = false
      this.isLoading = true
      await this.confirmCallback(this.message)
      this.isLoading = false
    },
    meta(level) {
      if (level) {
        return levels[level.toLowerCase()] || levels.info
      }
      return levels.info
    }
  }
}
