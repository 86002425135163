import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory'
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { onError } from 'apollo-link-error'
import gql from 'graphql-tag'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { getState } from '~/lib/thing/getState'
import fragmentTypes from '~/config/fragmentTypes'

export default ({ store, $envConfig, $auth, error, app }) => {
  const isSSO = $auth?.strategy.name === 'sso'
  const type = isSSO
    ? AUTH_TYPE.OPENID_CONNECT
    : AUTH_TYPE.AMAZON_COGNITO_USER_POOLS
  const jwtToken = isSSO
    ? async () => await $auth?.strategy?.token.get()
    : async () => await store.getters['user/token']
  const config = {
    url: $envConfig?.API_GRAPHQL,
    auth: {
      type,
      jwtToken
    },
    reconnect: true,
    disableOffline: true
  }

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragmentTypes
  })

  const errorLink = onError((err) => {
    const { graphQLErrors, networkError } = err
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        // eslint-disable-next-line no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      })
    const hasErrorMessages = networkError?.errors?.length > 0
    if (hasErrorMessages) {
      const networkErrorMessage = networkError.errors[0].message?.toLowerCase()
      switch (networkErrorMessage) {
        case 'subscribe only available for aws appsync endpoint':
          // AppSync url not loaded properly
          error({
            statusCode: 500,
            message: app.i18n.t('warnings.invalid_appsync_url')
          })
          break
        default:
          // eslint-disable-next-line no-console
          console.log(`[Network error]`, err)
      }
    }
  })

  // this generates a js error "You are calling concat on a terminating link, which will have no effect"
  // this error can be remove by change the order of those Links.
  // but if the subscription link comes before the AuthLink all query missing the jwt token.
  // known behavior since 2019: https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/448
  const link = ApolloLink.from([
    errorLink,
    new RetryLink({
      attempts: (count, _operation, error) => {
        if (!error || !error.errors || error.errors.length <= 0) return false
        const socketError = error.errors[0].message === 'Connection closed'

        // only show "live update not possible" message on second reconnect retry
        if (socketError && count === 2) {
          store.dispatch('notify/dialog', {
            message: app.i18n.t('warnings.ws_disconnected'),
            dismiss: true,
            timeout: 30000 // 30 seconds
          })
        }

        if (socketError) {
          // always retry the reestablished socket connection
          return true
        } else {
          // retry on non socket errors max 15 times
          return count <= 15
        }
      },
      delay: (count, _operation, error) => {
        const socketError = error?.errors[0]?.message === 'Connection closed'
        if (socketError) {
          // retry max 30 seconds apart in rising delays (from 1 sec 30 sec)
          return Math.min(count * 1000, 30000) * Math.random()
        } else {
          // retry max 10 seconds apart in rising delays (from 0.5 sec 10 sec)
          return Math.min(count * 500, 10000) * Math.random()
        }
      }
    }),
    createAuthLink(config),
    createSubscriptionHandshakeLink(config)
  ])

  const cache = new InMemoryCache({ fragmentMatcher })

  return {
    link,
    cache,
    resolvers: {
      Thing: {
        localState: (thing) => {
          return getState(thing.state, thing.ts_last_seen)
        }
      },
      Mutation: {
        updateThingsState: (_, _args, { cache }) => {
          const query = gql`
            query OrgThingsQuery {
              orgData: getRootOrg @client {
                things {
                  id
                  state
                  ts_last_seen
                }
              }
            }
          `
          try {
            const data = cache.readQuery({ query })
            for (const thing of data?.orgData.things) {
              const localState = getState(thing.state, thing.ts_last_seen)
              if (localState !== thing.localState) {
                cache.writeData({
                  id: `${thing.__typename}:${thing.id}`,
                  data: {
                    localState
                  }
                })
              }
            }
          } catch (e) {}
          return null
        }
      }
    }
  }
}
