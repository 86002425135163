import { isString } from 'lodash-es'
import { colorMap, IColorMap } from '~/lib/utils/color-map'

export const stringToColor = (string: string): string => {
  const firstCharacter = getFirstCharacter(string)

  if (isString(firstCharacter)) {
    return (colorMap as IColorMap)[firstCharacter]
  }

  return colorMap.default
}

const getFirstCharacter = (string: string): string | null => {
  if (!isString(string) || string.length === 0) {
    return null
  }

  return string[0].toLowerCase()
}

export const toLowerCase = (string: string): string => {
  if (isString(string)) {
    return string.toLowerCase()
  }

  return ''
}

export const isULID = (string: string): boolean => {
  const ulidRegex = /^[0-7][0-9a-hjkmnp-tv-zA-HJKMNP-TV-Z]{25}$/
  return ulidRegex.test(string)
}
